import { Component, Input, OnInit } from '@angular/core'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ParametreSpecfiqueBddEnedisDto } from '../../../data-access/http/dto/parametre-specfique-bdd-enedis-dto'
import { ParametreDto } from '../../../data-access/http/dto/parametre-dto'
import { ParametreService } from '../../../data-access/http/parametre.service'
import { ParametreSpecifiqueSaveDto } from '../../../data-access/http/dto/parametre-specifique-save-dto'

@Component({
  selector: 'app-parametre-specifique-bdd-enedis-modal',
  templateUrl: './parametre-specifique-bdd-enedis-modal.component.html',
  styleUrls: ['./parametre-specifique-bdd-enedis-modal.component.scss']
})
export class ParametreSpecifiqueBddEnedisModalComponent implements OnInit {

  @Input()
  parametre: ParametreDto
  parametreTableauDto: ParametreSpecfiqueBddEnedisDto
  formGroup: FormGroup = this.formBuilder.group({})
  displayTableau: boolean = false
  formModalIsSubmitting: boolean = false

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public modalService: NgbModal,
    private parametreService: ParametreService,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {

    this.parametreService.getParametresBddEnedis(this.parametre.code).subscribe((parametreBddEnedis) => {
      this.parametreTableauDto = parametreBddEnedis
      this.formGroup.addControl('datas', this.generateFormData(parametreBddEnedis))
      this.displayTableau = true
    })
  }

  private generateFormData(parametreTableau: ParametreSpecfiqueBddEnedisDto): FormArray {
      const formArrayData = this.formBuilder.array([])
      for (const ligne of parametreTableau.lignes) {
        formArrayData.push(this.formBuilder.control(ligne.quotepart, Validators.required))
      }
      return formArrayData
  }

  getValidationMessage(index: number) {
    const control = (this.formGroup.get('datas') as FormArray).controls[index]
    if (control.hasError('required')) {
      return 'La quote-part est obligatoire'
    }
    return ''
  }

  // Cette fonction vérifie si le FormControl associé à l'indice donné est invalide
  isInvalidQuotePart(index: number) {
    const control = (this.formGroup.get('datas') as FormArray).controls[index]
    return control.invalid && (control.dirty || control.touched)
  }

  onSubmit(): void {
    if (this.formGroup.valid) {
      this.formModalIsSubmitting = true
      const dto:ParametreSpecifiqueSaveDto = this.revertData(this.parametreTableauDto)
      dto.parametreDto = this.parametre
      this.formModalIsSubmitting = false
      this.modalService.dismissAll()
      this.parametreService.saveParametreBddEnedis(this.parametre, this.parametreTableauDto).subscribe(
        {next:(data: any) => {
          this.toastr.success('Le paramètre ' + this.parametre.code + ' a été enregistré avec succès.')
          this.formModalIsSubmitting = false
          this.activeModal.dismiss(data)
        },
        error:() => {
          this.toastr.error("Erreur lors de l'enregistrement du paramètre.")
          this.formModalIsSubmitting = false
          this.activeModal.dismiss()
        }}
      )
    }
  }

  revertData(datas: ParametreSpecfiqueBddEnedisDto):ParametreSpecifiqueSaveDto {
    const result = []
    for (let i = 0; i < datas.lignes.length; i++) {
      datas.lignes[i].quotepart = this.formGroup.value.datas[i]
      result.push(datas.lignes[i])
    }
    return { ...datas, datas: result }
  }

  fermerModal(): void {
    // Fermez le modal en appelant la méthode close sur l'objet MatDialogRef
    this.activeModal.dismiss()
  }
}
