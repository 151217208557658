import { Injectable } from '@angular/core'
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { catchError, Observable, throwError } from 'rxjs'
import { ToastrService } from 'ngx-toastr'
import { Router } from '@angular/router'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private toastr: ToastrService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        this.showError(error)
        return throwError(error)
      })
    )
  }

  private showError(httpErrorResponse: HttpErrorResponse) {
    this.redirectOnErrorPage(httpErrorResponse)
    this.showErrorToast(httpErrorResponse)
  }

  private redirectOnErrorPage(httpErrorResponse: HttpErrorResponse) {
    if (httpErrorResponse.status === 404) {
      this.router.navigate(['/not-found'], { skipLocationChange: true })
    }
  }

  private showErrorToast(httpErrorResponse: HttpErrorResponse) {
    if (typeof httpErrorResponse.error.error_description === 'string') {
      this.toastr.error(httpErrorResponse.error.error_description, `Erreur ${httpErrorResponse.status}`)
    } else if (httpErrorResponse.error) {
      this.toastr.error(httpErrorResponse.error.message, `Erreur ${httpErrorResponse.status}`)
    } else {
      this.toastr.error(httpErrorResponse.error)
    }
  }
}
