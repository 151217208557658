<div [formGroup]="modeValorisationForm">
  <div class='container'>
    <div class="card">
      <div class="card-body">
        <h2>Mode valorisation <span class="required">*</span></h2>
        <div class="row">
          <div class="btn-group d-block" role="group">
            <ng-container *ngFor="let modeValorisation of modeValorisations">
              <input type="radio" class="btn-check" name="options" [value]="modeValorisation.code" id="{{ modeValorisation.code }}"
                     (click)='onRadioClick(modeValorisation.code, "modeValorisation")' tabindex="1">
              <label class="btn btn-outline-success" for="{{ modeValorisation.code }}"> {{ modeValorisation.code }} - {{ modeValorisation.label }}</label>
            </ng-container>
          </div>
          <input type="hidden" formControlName="modeValorisation" name="modeValorisation"
                 [ngClass]="modeValorisationForm.get('modeValorisation').invalid && (modeValorisationForm.get('modeValorisation').dirty || modeValorisationForm.get('modeValorisation').touched) ? 'is-invalid' : ''">
          <div class="invalid-feedback">
            <div *ngIf="modeValorisationForm.get('modeValorisation').errors?.['required']">Le mode valorisation est obligatoire</div>
          </div>
        </div>
      </div>
    </div>
    <div class='card' *ngIf="modeValorisationForm.get('modeValorisation').value === 'VT'">
      <div class='card-body'>
        <div class="row">
          <label for="distanceRaccordementPublic" class="form-label">Distance raccordement public <span class="required">*</span></label>
          <div class="col-11">
            <input type="number" class="form-control" id="distanceRaccordementPublic" formControlName="distanceRaccordementPublic" placeholder="Ex : 400 m" tabindex="33"
               [attr.disabled]="true"
               [ngClass]="modeValorisationForm.get('distanceRaccordementPublic').invalid && (modeValorisationForm.get('distanceRaccordementPublic').dirty
                || modeValorisationForm.get('distanceRaccordementPublic').touched) ? 'is-invalid' : ''">
            <div class="invalid-feedback">
              <div *ngIf="modeValorisationForm.get('distanceRaccordementPublic').errors?.['required']">La distance raccordement public est obligatoire</div>
            </div>
          </div>
          <div class="col-1">
            <span class="spanTextMiddle">m</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="modeValorisationForm.get('modeValorisation').value === 'AC'">
      <div class='card'>
        <div class='card-body'>
            <div class="row">
              <div class="col-12 col-xxl-6">
                <div class="row">
                    <label for="puissanceSouscriteKVA" class="form-label">Puissance souscrite  <span class="required">*</span></label>
                    <div class="col-10">
                      <input type="number" class="form-control" id="puissanceSouscriteKVA" formControlName="puissanceSouscriteKVA" placeholder="Ex : 343 kVA" tabindex="34"
                             [ngClass]="modeValorisationForm.get('puissanceSouscriteKVA').invalid && (modeValorisationForm.get('puissanceSouscriteKVA').dirty
                                || modeValorisationForm.get('puissanceSouscriteKVA').touched) ? 'is-invalid' : ''">
                      <div class="invalid-feedback">
                        <div *ngIf="modeValorisationForm.get('puissanceSouscriteKVA').errors?.['pattern']">La puissance souscrite n'est pas valide</div>
                        <div *ngIf="modeValorisationForm.get('puissanceSouscriteKVA').errors?.['required']">La puissance souscrite est obligatoire</div>
                      </div>
                    </div>
                    <div class="col-2">
                      <span class="spanTextMiddle">kVA</span>
                    </div>
                </div>
              </div>
              <div class="col-12 col-xxl-6">
                <div class="row">
                  <label for="travauxDistanceVoirieVrd" class="form-label">Distance voirie VRD</label>
                  <div class="col-10">
                    <input type="number" class="form-control" id="travauxDistanceVoirieVrd" formControlName="travauxDistanceVoirieVrd" placeholder="Ex : 500" tabindex="35"
                     [attr.disabled]="true" >
                  </div>
                  <div class="col-2">
                    <span class="spanTextMiddle">m</span>
                  </div>
                </div>
              </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group form-check">
                <span class="spanCheckbox">Demande client ajouter dispositif non injection ?</span>
                <input class="form-check-input" type="checkbox" formControlName="dispositifNonInjection" tabindex="36">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='card'>
        <div class='card-body'>
          <h2 class="card-title">Informations site</h2>
          <div class="row">
            <div class="col-12 col-xxl-6">
              <div class="form-group form-check">
                <span class="spanCheckbox">Disponibilité TGBT client ?</span>
                <input class="form-check-input" type="checkbox" formControlName="dispoTGBT" tabindex="37">
              </div>
            </div>
            <div class="col-12 col-xxl-6">
              <div class="form-group form-check">
                <span class="spanCheckbox">Automate déjà présent client ?</span>
                <input class="form-check-input" type="checkbox" formControlName="automate" tabindex="38">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='card' style='padding-bottom: 5px;'>
        <div class='card-body'>
          <h2 class="card-title">Bilan energétique</h2>
            <div class="row">
              <div class="col-10 col-xxl-10">
                    <label for="prm" class="form-label">Courbe de charge (PRM) <span class="required">*</span></label>
                    <ng-select id="prm" formControlName="prm" [clearable]="false" tabindex="39"
                               [ngClass]="modeValorisationForm.get('prm').invalid && (modeValorisationForm.get('prm').dirty || modeValorisationForm.get('prm').touched) ? 'is-invalid' : ''">
                      <ng-option *ngFor="let prmLigne of prmCodes" [value]="prmLigne.code">{{prmLigne.code}}</ng-option>
                    </ng-select>
                    <div class="invalid-feedback">
                      <div *ngIf="modeValorisationForm.get('prm').errors?.['required']">Le prm est obligatoire</div>
                    </div>
              </div>
              <div class="col-2 col-xxl-2">
                <div class='mb-2'>
                  <label for="fichier-button" class="form-label"></label>
                </div>
                <button type="button" id="fichier-button" (click)="openModal()"
                        class="btn btn-primary"  [disabled]="formIsSubmitting"
                        title='Charger nouveau fichier'>
                         <i class="bi bi-upload custom-outils-bi"></i>
                  </button>
              </div>
            </div>
        </div>

        <div class='container'>
        <h2 class="card-title">Tarifs de l'énergie (c€/kWh)</h2>
          <div class="row">

            <div class="col-12 col-xxl-6">
              <label for="tarifHch" class="form-label">Heure Creuse Haute (HCH)  <span class="required">*</span></label>
              <input type="number" class="form-control" id="tarifHch" formControlName="tarifHch" placeholder="Ex : 3.07" tabindex="40"
                     [ngClass]="modeValorisationForm.get('tarifHch').invalid && (modeValorisationForm.get('tarifHch').dirty
                  || modeValorisationForm.get('tarifHch').touched) ? 'is-invalid' : ''">
              <div class="invalid-feedback">
                <div *ngIf="modeValorisationForm.get('tarifHch').errors?.['required']">Heure Creuse Haute est obligatoire</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-xxl-6">
              <label for="tarifHph" class="form-label">Heure Pleine Haute (HPH)  <span class="required">*</span></label>
              <input type="number" class="form-control" id="tarifHph" formControlName="tarifHph" placeholder="Ex : 3.07" tabindex="41"
                     [ngClass]="modeValorisationForm.get('tarifHph').invalid && (modeValorisationForm.get('tarifHph').dirty
                  || modeValorisationForm.get('tarifHph').touched) ? 'is-invalid' : ''">
              <div class="invalid-feedback">
                <div *ngIf="modeValorisationForm.get('tarifHph').errors?.['required']">Heure Pleine Haute est obligatoire</div>
              </div>
            </div>
            <div class="col-12 col-xxl-6">
              <label for="tarifHpt" class="form-label">Heure Pointe (HPT)  <span class="required">*</span></label>
              <input type="number" class="form-control" id="tarifHpt" formControlName="tarifHpt" placeholder="Ex : 3.07" tabindex="42"
                       [ngClass]="modeValorisationForm.get('tarifHpt').invalid && (modeValorisationForm.get('tarifHpt').dirty
                    || modeValorisationForm.get('tarifHpt').touched) ? 'is-invalid' : ''">
              <div class="invalid-feedback">
                <div *ngIf="modeValorisationForm.get('tarifHpt').errors?.['required']">Heure Pointe est obligatoire</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-xxl-6">
              <label for="tarifHcb" class="form-label">Heure Creuse Basse (HCB)  <span class="required">*</span></label>
              <input type="number" class="form-control" id="tarifHcb" formControlName="tarifHcb" placeholder="Ex : 3.07" tabindex="43"
                     [ngClass]="modeValorisationForm.get('tarifHcb').invalid && (modeValorisationForm.get('tarifHcb').dirty
                  || modeValorisationForm.get('tarifHcb').touched) ? 'is-invalid' : ''">
              <div class="invalid-feedback">
                <div *ngIf="modeValorisationForm.get('tarifHcb').errors?.['required']">Heure Creuse Basse est obligatoire</div>
              </div>
            </div>
            <div class="col-12 col-xxl-6">
              <label for="tarifHpb" class="form-label">Heure Pleine Basse (HPB)  <span class="required">*</span></label>
              <input type="number" class="form-control" id="tarifHpb" formControlName="tarifHpb" placeholder="Ex : 3.07" tabindex="44"
                         [ngClass]="modeValorisationForm.get('tarifHpb').invalid && (modeValorisationForm.get('tarifHpb').dirty
                      || modeValorisationForm.get('tarifHpb').touched) ? 'is-invalid' : ''">
              <div class="invalid-feedback">
                <div *ngIf="modeValorisationForm.get('tarifHpb').errors?.['required']">Heure Pleine Basse est obligatoire</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Intégration du fichier courbe de charge Enedis</h5>
    <button type="button" class="btn-close btn-primary" aria-label="Close" (click)="modal.dismiss('Cross click')">
    </button>
  </div>
  <div class="modal-body">
    <input type="file" (change)="onFileSelected($event)" accept=".csv">
  </div>
  <div class="modal-footer d-flex flex-column align-items-stretch">
    <div class="row">
      <div class="col-7">
        <button type="button" class="btn btn-modal btn-danger" (click)="modal.close('Close click')">Fermer</button>
      </div>
      <div class="col-5">
        <button type="submit" class="btn btn-modal btn-primary" (click)="onSubmit() ">Valider
          <div *ngIf="formModalIsSubmitting" class="col-3 text-center spinner-border text-success" role="status"></div>
        </button>
      </div>
    </div>
  </div>
</ng-template>
