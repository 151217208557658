import { ElementRef, inject, Injectable } from '@angular/core'
import { Zone } from '../model/zone'
import Map from 'ol/Map'
import { fromLonLat, Projection } from 'ol/proj'
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  fromEvent,
  map,
  Observable,
  startWith,
  Subject,
} from 'rxjs'
import { ToolEnum } from '../model/tool.enum'
import { Geometry, LineString, Point, Polygon } from 'ol/geom'
import { ZoneMeasures } from '../model/zone-measures'
import VectorSource from 'ol/source/Vector'
import { Draw, Modify, Select } from 'ol/interaction'
import { InteractionUtils } from '../utils/interaction-utils'
import { MeasureUtils } from '../utils/measure.utils'
import { MapUtils } from '../utils/map.utils'
import { MapBrowserEvent, Overlay } from 'ol'
import { FeatureLike } from 'ol/Feature'
import { TooltipUtils } from '../utils/tooltip.utils'
import { GeocodingService } from '../data-access/http/geocoding.service'

@Injectable({ providedIn: 'root' })
export class GeomapDomainService {
  state: BehaviorSubject<GeomapStateModel> = new BehaviorSubject<GeomapStateModel>({
    map: MapUtils.initMap(),
    zones: [new Zone(null, null), new Zone(null, null), new Zone(null, null)],
    activeZoneId: 0,
    activeTool: ToolEnum.None,
    selectedModeValorisation: false,
    selectedTypePose: [false, false, false],
    selectedBarreOutils: false,
    selectedDessinOutilsSeulementZone: false,
  })

  private geocodingService = inject(GeocodingService)

  // Selectors
  activeZoneId$: Observable<number> = this.state.asObservable().pipe(
    map((state) => state.activeZoneId),
    distinctUntilChanged()
  )
  activeTool$: Observable<ToolEnum> = this.state.asObservable().pipe(
    map((state) => state.activeTool),
    distinctUntilChanged()
  )

  selectedModeValorisation$: Observable<boolean> = this.state.asObservable().pipe(
    map((state) => state.selectedModeValorisation),
    distinctUntilChanged()
  )

  selectedTypePoseCurrentZone$: Observable<boolean> = this.state.asObservable().pipe(
    map((state) => state.selectedTypePose[this.activeZoneId]),
    distinctUntilChanged()
  )

  selectedOutils$: Observable<boolean> = this.state.asObservable().pipe(
    map((state) => state.selectedBarreOutils),
    distinctUntilChanged()
  )

  selectedDessinOutilsSeulementZone$: Observable<boolean> = this.state.asObservable().pipe(
    map((state) => state.selectedDessinOutilsSeulementZone),
    distinctUntilChanged()
  )

  currentZoneHasBeanReset$: Subject<number> = new Subject<number>()

  constructor() {
    this.setZone(0)
    const bodyStyles = window.getComputedStyle(document.body)
    this.state.next({
      ...this.state.value,
      zones: [
        new Zone(bodyStyles.getPropertyValue('--colorSurfaceZone1'), bodyStyles.getPropertyValue('--colorZone1')),
        new Zone(bodyStyles.getPropertyValue('--colorSurfaceZone2'), bodyStyles.getPropertyValue('--colorZone2')),
        new Zone(bodyStyles.getPropertyValue('--colorSurfaceZone3'), bodyStyles.getPropertyValue('--colorZone3')),
        new Zone(
          bodyStyles.getPropertyValue('--colorSurfaceModeValorisation'),
          bodyStyles.getPropertyValue('--colorModeValorisation')
        ),
      ],
    })
  }

  get map(): Map {
    return this.state.value.map
  }

  get currentZone(): Zone {
    return this.state.value.zones[this.state.value.activeZoneId]
  }

  get activeZoneId(): number {
    return this.state.value.activeZoneId
  }

  getGpsData$ = (): Observable<number[]> =>
    fromEvent(this.state.value.zones[0].vectorLayer.getSource(), 'change').pipe(
      map((event: any) =>
        (
          (
            (event.target as VectorSource)
              .getFeatures()
              .find((feature) => feature.getGeometry().getType() === 'Polygon')
              ?.getGeometry() as Polygon
          )
            ?.getInteriorPoint()
            .transform(new Projection({ code: 'EPSG:900913' }), new Projection({ code: 'EPSG:4326' })) as Point
        )?.getCoordinates()
      ),
      startWith([0, 0])
    )

  getAreaForZone$ = (zoneId: number): Observable<number> =>
    fromEvent(this.state.value.zones[zoneId].vectorLayer.getSource(), 'change').pipe(
      map((event: any) => {
        const surfaces = (event.target as VectorSource)
          .getFeatures()
          .map((feature) => feature.getGeometry())
          .filter((geometry) => geometry.getProperties()['type'] === ToolEnum.Surface)
        return surfaces.reduce((acc, area) => acc + MeasureUtils.computeArea(area as Polygon), 0)
      }),
      startWith(null)
    )

  getAzimuthForZone$ = (zoneId: number): Observable<number> =>
    fromEvent(this.state.value.zones[zoneId].vectorLayer.getSource(), 'change').pipe(
      map((event: any) => {
        const [azimuthDraw] = (event.target as VectorSource)
          .getFeatures()
          .map((feature) => feature.getGeometry())
          .filter((geometry) => geometry.getProperties()['type'] === ToolEnum.Azimuth)
        if (azimuthDraw) {
          return MeasureUtils.computeAzimuth(azimuthDraw as LineString)
        }
        return null
      }),
      startWith(null)
    )

  getDistance$ = (): Observable<number> =>
    fromEvent(this.state.value.zones[3].vectorLayer.getSource(), 'change').pipe(
      map((event: any) => {
        const lengths = (event.target as VectorSource)
          .getFeatures()
          .map((feature) => feature.getGeometry())
          .filter((geometry) => geometry.getProperties()['type'] === ToolEnum.Length)
        return lengths.reduce((acc, length) => acc + MeasureUtils.computeLength(length as LineString), 0)
      }),
      startWith(null)
    )

  getAllMeasuresForZone$ = (zoneId: number) =>
    //combineLatest([this.getAreaForZone$(zoneId), this.getAzimuthForZone$(zoneId), this.getLengthForZone$(zoneId)]).pipe(
    combineLatest([this.getAreaForZone$(zoneId), this.getAzimuthForZone$(zoneId)]).pipe(
      map<number[], ZoneMeasures>(([surface, azimuth]) => ({ surface, azimuth }))
    )

  // Actions
  positionMap(coordinates: number[]): void {
    this.map.getView().setCenter(fromLonLat(coordinates))
  }

  positionGoogleMap(placeId: string): void {
    this.geocodingService.geoCodeGoogle(placeId).subscribe(
      data => {
        const coordinates: number[] = [data.lng,data.lat]
        this.map.getView().setCenter(fromLonLat(coordinates))
      }
    );
  }

  disableTools(): void {
    MapUtils.resetMapToDefaultInteractions(this.map)
    const select = new Select({})

    const modify = new Modify({
      features: select.getFeatures(),
    })
    this.map.addInteraction(select)
    this.map.addInteraction(modify)
    this.state.next({ ...this.state.value, activeTool: ToolEnum.None })
  }

  selectModeValorisationOutils(): void {
    this.state.next({ ...this.state.value, selectedModeValorisation: true })
    this.selectionBarreOutils()
  }

  selectTypePoseOutils(zoneIndex: number): void {
    const copySelectedTypePose = [...this.state.value.selectedTypePose]
    copySelectedTypePose[zoneIndex] = true
    this.state.next({ ...this.state.value, selectedTypePose: copySelectedTypePose })
  }

  deselectTypePoseOutils(zoneIndex: number): void {
    const copySelectedTypePose = [...this.state.value.selectedTypePose]
    copySelectedTypePose[zoneIndex] = false
    this.state.next({ ...this.state.value, selectedTypePose: copySelectedTypePose })
  }

  setZone(zoneIndex: number): void {
    if(this.currentZone) {
      this.map.removeLayer(this.currentZone.vectorLayer)
    }
    const newZone = this.state.value.zones[zoneIndex]
    this.map.addLayer(newZone.vectorLayer)
    this.state.next({ ...this.state.value, activeZoneId: zoneIndex })
    this.disableTools()
  }

  resetCurrentZone(): void {
    this.currentZone.vectorLayer.getSource().clear()
    this.currentZoneHasBeanReset$.next(this.activeZoneId)
    this.deselectTypePoseOutils(this.activeZoneId)
    this.deselectionBarreOutils()
    this.deselectionDessinOutilsSeulementZone()
  }

  hiddenCurrentZone(): void {
    this.map.removeLayer(this.currentZone.vectorLayer)
    this.state.next({ ...this.state.value, activeZoneId: null })
  }

  resetDessinModeValorisation(): void {
    this.currentZone.vectorLayer.getSource().clear()
  }

  activateMeasureAreaTool(): void {
    MapUtils.resetMapToDefaultInteractions(this.map)
    const { vectorLayer, color } = this.currentZone
    this.map.addInteraction(InteractionUtils.buildAreaInteraction(vectorLayer.getSource(), color))
    this.state.next({ ...this.state.value, activeTool: ToolEnum.Surface })
  }

  activateMeasureAzimuthTool(): void {
    MapUtils.resetMapToDefaultInteractions(this.map)
    const { vectorLayer, color } = this.currentZone
    this.map.addInteraction(InteractionUtils.buildAzimuthInteraction(vectorLayer.getSource(), color))
    this.state.next({ ...this.state.value, activeTool: ToolEnum.Azimuth })
  }

  activateMeasureDistanceTool(): void {
    MapUtils.resetMapToDefaultInteractions(this.map)
    const { vectorLayer, color } = this.currentZone
    this.map.addInteraction(InteractionUtils.buildLengthInteraction(vectorLayer.getSource(), color))
    this.state.next({ ...this.state.value, activeTool: ToolEnum.Length })
  }

  activateDeleteTool(): void {
    MapUtils.resetMapToDefaultInteractions(this.map)
    const { vectorLayer } = this.currentZone
    this.map.addInteraction(InteractionUtils.buildDeleteInteraction(vectorLayer.getSource()))
    this.state.next({ ...this.state.value, activeTool: ToolEnum.Delete })
  }

  activateTooltips(tooltipElementRef: ElementRef): void {
    const tooltipElement = tooltipElementRef.nativeElement
    const overlay = new Overlay({
      element: tooltipElement,
      offset: [0, 0],
      positioning: 'center-center',
    })

    this.map.on('pointermove', (evt: MapBrowserEvent<PointerEvent>) => {
      overlay.setPosition(null)
      this.map.forEachFeatureAtPixel(evt.pixel, (feature: FeatureLike) => {
        if (feature.getGeometry().getType() !== 'Point') {
          tooltipElement.innerHTML = TooltipUtils.getTooltipLabel(feature.getGeometry() as Geometry)
          overlay.setPosition(TooltipUtils.getTooltipPosition(feature.getGeometry() as Geometry))
        }
      })
    })
    this.activeTool$.subscribe((tool) => {
      const currentInteraction = this.map.getInteractions().item(this.map.getInteractions().getLength() - 1)
      if (currentInteraction instanceof Draw) {
        currentInteraction.on('drawstart', (event) => {
          event.feature.getGeometry().on('change', (event) => {
            overlay.setPosition(TooltipUtils.getTooltipPosition(event.target))
            tooltipElement.innerHTML = TooltipUtils.getTooltipLabel(event.target)
          })
        })
        this.map.addOverlay(overlay)
        currentInteraction.on('drawend', (event) => {
          overlay.setPosition(null)
        })
      }
    })
  }

  selectionBarreOutils(): void {
    this.state.next({ ...this.state.value, selectedBarreOutils: true })
  }

  deselectionBarreOutils(): void {
    this.state.next({ ...this.state.value, selectedBarreOutils: false })
  }

  selectionDessinOutilsSeulementZone(): void {
    this.state.next({ ...this.state.value, selectedDessinOutilsSeulementZone: true })
  }

  deselectionDessinOutilsSeulementZone(): void {
    this.state.next({ ...this.state.value, selectedDessinOutilsSeulementZone: false })
  }

}

export interface GeomapStateModel {
  map: Map
  zones: Zone[]
  activeZoneId: number
  activeTool: ToolEnum
  selectedModeValorisation: boolean
  selectedTypePose: boolean[],
  selectedBarreOutils: boolean
  selectedDessinOutilsSeulementZone: boolean
}
