import {Component} from '@angular/core'
import {OAuthService} from 'angular-oauth2-oidc'

@Component({
  selector: 'app-lg-user-not-authenticated',
  templateUrl: './user-not-authenticated.component.html',
  styleUrls: ['./user-not-authenticated.component.scss'],
})
export class UserNotAuthenticatedComponent {
  constructor(public oAuthService: OAuthService) {}

  logout() {
    this.oAuthService.logOut({
      client_id: this.oAuthService.clientId,
      redirect_uri: this.oAuthService.redirectUri,
      response_type: 'code',
    })
  }
}
