import { AfterViewInit, Component, EventEmitter, inject, OnInit, Output } from '@angular/core'
import { NgClass, NgForOf, NgIf } from '@angular/common'
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { TypeReferenceDto } from '../../../sunleon/data-access/http/dto/type-reference-dto'
import { ReferenceService } from '../../../sunleon/data-access/http/reference.service'
import { GeomapDomainService } from '../../domain/geomap.domain'
import { ZoneMeasures } from '../../model/zone-measures'

@Component({
  selector: 'app-form-zone1',
  templateUrl: './form-zone1.component.html',
  styleUrls: ['./form-zone1.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, NgForOf, ReactiveFormsModule, NgClass],
})
export class FormZone1Component implements OnInit, AfterViewInit {
  @Output()
  formReady: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  private readonly geomapDomainService = inject(GeomapDomainService)
  readonly measuresZone1$ = this.geomapDomainService.getAllMeasuresForZone$(0)

  zone1Form: FormGroup
  typePoses: TypeReferenceDto[] = []
  typeToitures: TypeReferenceDto[] = []
  typePlaces: TypeReferenceDto[] = []
  typeParkings: TypeReferenceDto[] = []

  ngOnInit(): void {
    this.referenceService.getTypePose().subscribe((data) => {
      this.typePoses = data
    })
    this.referenceService.getTypeToiture().subscribe((data) => {
      this.typeToitures = data
    })
    this.referenceService.getTypeParking().subscribe((data) => {
      this.typeParkings = data
    })
    this.referenceService.getTypePlace().subscribe((data) => {
      this.typePlaces = data
    })
    this.formReady.emit(this.zone1Form)
  }
  ngAfterViewInit(): void {
    this.measuresZone1$.subscribe((zoneMeasures: ZoneMeasures) => {
      this.zone1Form.get('surfaceZone1').patchValue(zoneMeasures.surface === 0 ? null : zoneMeasures.surface)
      this.zone1Form.get('azimutZone1').patchValue(zoneMeasures.azimuth)
    })
  }

  onRadioClickZone1(code: string | null, controleName: string) {
    this.zone1Form.get(controleName).setValue(code)
    this.geomapDomainService.selectTypePoseOutils(0)
    this.geomapDomainService.selectionBarreOutils()
    this.geomapDomainService.selectionDessinOutilsSeulementZone()
  }

  constructor(private formBuilder: FormBuilder, private referenceService: ReferenceService) {
    this.zone1Form = this.formBuilder.group({
      typePoseZone1: [null, [Validators.required]],
      surfaceZone1: [null, [Validators.required, Validators.pattern('^\\d+(\\.\\d{1,2})?$')]],
      azimutZone1: [null, [Validators.required, Validators.pattern('^-?(180|\\d{1,2}|1[0-7]\\d)$')]],
      typeToitureZone1: [''],
      pvReadyZone1: [false],
      inclinaisonZone1: [null, [Validators.pattern('^\\d+$')]],
      typeVehiculeParkingZone1: [''],
      typePlaceParkingZone1: [''],
    })

    this.initTypePoseZone(
      'typePoseZone1',
      'deposeCandelabre',
      'poseCandelabre',
      'eclairageSousOmbriere',
      'nbAbattageArbres',
      'mlClotureSol',
      'nbPortailsSol',
      'surfaceZone1',
      'azimutZone1',
      'typeToitureZone1',
      'pvReadyZone1',
      'inclinaisonZone1',
      'typeVehiculeParkingZone1',
      'typePlaceParkingZone1'
    )
  }

  initTypePoseZone(
    typePose: string,
    deposeCandelabre: string,
    poseCandelabre: string,
    eclairageSousOmbriere: string,
    nbAbattageArbres: string,
    mlClotureSol: string,
    nbPortailsSol: string,
    surfaceZone: string,
    azimutZone: string,
    typeToitureZone: string,
    pvReadyZone: string,
    inclinaisonZone: string,
    typeVehiculeParkingZone: string,
    typePlaceParkingZone: string
  ) {
    this.zone1Form.get(typePose).valueChanges.subscribe((typePoseZone) => {
      const constSurfaceZone = this.zone1Form.get(surfaceZone)
      const constAzimutZone = this.zone1Form.get(azimutZone)
      const constTypeToitureZone = this.zone1Form.get(typeToitureZone)
      const constPvReadyZone = this.zone1Form.get(pvReadyZone)
      const constInclinaisonZone = this.zone1Form.get(inclinaisonZone)
      const constTypeVehiculeParkingZone = this.zone1Form.get(typeVehiculeParkingZone)
      const constTypePlaceParkingZone = this.zone1Form.get(typePlaceParkingZone)

      if (typePoseZone) {
        constSurfaceZone.setValidators([Validators.required, Validators.pattern('^\\d+(\\.\\d{1,2})?$')])
        constAzimutZone.setValidators([Validators.required, Validators.pattern('^-?(180|\\d{1,2}|1[0-7]\\d)$')])

        if (typePoseZone === 'TOITURE') {
          constTypeToitureZone.setValidators([Validators.required])
          constPvReadyZone.setValidators([Validators.required])
          constInclinaisonZone.setValidators([Validators.required])
          constTypeToitureZone.reset()
          constPvReadyZone.reset()
          constInclinaisonZone.reset()

          constTypeVehiculeParkingZone.setValidators([Validators.nullValidator])
          constTypePlaceParkingZone.setValidators([Validators.nullValidator])
          constTypeVehiculeParkingZone.reset()
          constTypePlaceParkingZone.reset()
        }
        if (typePoseZone === 'OMBRIERE') {
          constTypeVehiculeParkingZone.setValidators([Validators.required])
          constTypeVehiculeParkingZone.reset()
          constTypePlaceParkingZone.reset()

          constTypeToitureZone.setValidators([Validators.nullValidator])
          constPvReadyZone.setValidators([Validators.nullValidator])
          constInclinaisonZone.setValidators([Validators.nullValidator])

          constTypeToitureZone.reset()
          constPvReadyZone.reset()
          constInclinaisonZone.reset()
        }

        if (typePoseZone === 'SOL') {
          constTypeToitureZone.setValidators([Validators.nullValidator])
          constPvReadyZone.setValidators([Validators.nullValidator])
          constInclinaisonZone.setValidators([Validators.nullValidator])
          constTypeVehiculeParkingZone.setValidators([Validators.nullValidator])
          constTypePlaceParkingZone.setValidators([Validators.nullValidator])

          constTypeToitureZone.reset()
          constPvReadyZone.reset()
          constInclinaisonZone.reset()
          constTypeVehiculeParkingZone.reset()
          constTypePlaceParkingZone.reset()
        }
      } else {
        constSurfaceZone.setValidators([Validators.pattern('^\\d+(\\.\\d{1,2})?$')])
        constAzimutZone.setValidators([Validators.pattern('^-?(180|\\d{1,2}|1[0-7]\\d)$')])
        constTypeToitureZone.setValidators([Validators.nullValidator])
        constPvReadyZone.setValidators([Validators.nullValidator])
        constInclinaisonZone.setValidators([Validators.nullValidator])
        constTypeVehiculeParkingZone.setValidators([Validators.nullValidator])
        constTypePlaceParkingZone.setValidators([Validators.nullValidator])

        constSurfaceZone.reset()
        constAzimutZone.reset()
        constTypeToitureZone.reset()
        constPvReadyZone.reset()
        constInclinaisonZone.reset()
        constTypeVehiculeParkingZone.reset()
        constTypePlaceParkingZone.reset()
      }
    })

    this.zone1Form.get(typeVehiculeParkingZone).valueChanges.subscribe((typeVehiculeParking) => {
      const constTypeVehiculeParkingZone = this.zone1Form.get(typeVehiculeParkingZone)
      const constTypePlaceParkingZone = this.zone1Form.get(typePlaceParkingZone)
      constTypeVehiculeParkingZone.setValidators([Validators.required])
      if (typeVehiculeParking === 'VL') {
        constTypePlaceParkingZone.setValidators([Validators.required])
      } else {
        constTypePlaceParkingZone.setValidators([Validators.nullValidator])
        constTypePlaceParkingZone.reset()
      }
    })
  }
}
