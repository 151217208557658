<div [formGroup]="zone1Form">
  <div class='container'>
    <div class="card">
      <div class="card-body">
        <h2 class="card-title">Zone n°1</h2>
        <div class="row">
          <div class='col-12'>
            <label class="form-label">Type de pose <span class="required">*</span></label>
            <div class="btn-group d-block" role="group">
              <ng-container *ngFor="let typePose of typePoses">
                <input type="radio" formControlName="typePoseZone1" class="btn-check btn-type-pose-options" [value]="typePose.code" [id]="typePose.code + 1"
                       (change)='onRadioClickZone1(typePose.code, "typePoseZone1")' tabindex="1">
                <label class="btn btn-outline-success btn-type-pose-options" [for]="typePose.code + 1"> {{ typePose.label }}</label>
              </ng-container>
            </div>
            <input type="hidden" formControlName="typePoseZone1" name="typePoseZone1"
                   [ngClass]="zone1Form.get('typePoseZone1').invalid && (zone1Form.get('typePoseZone1').dirty || zone1Form.get('typePoseZone1').touched) ? 'is-invalid' : ''">
            <div class="invalid-feedback">
              <div *ngIf="zone1Form.get('typePoseZone1').errors?.['required']">Le type de pose est obligatoire</div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="zone1Form.get('typePoseZone1').value">
          <div class="row">
            <div class='col-12'>
              <label for="surfaceZone1" class="form-label">Surface <span class="required">*</span></label>
              <div class="row">
                <div class="col-10">
                  <input type="number" class="form-control" id="surfaceZone1" formControlName="surfaceZone1" placeholder="Ex : 45.32" tabindex="8"
                   [attr.disabled]="true"
                   [ngClass]="zone1Form.get('surfaceZone1').invalid && (zone1Form.get('surfaceZone1').dirty || zone1Form.get('surfaceZone1').touched) ? 'is-invalid' : ''">
                  <div class="invalid-feedback">
                    <div *ngIf="zone1Form.get('surfaceZone1').errors?.['required']">La surface est obligatoire</div>
                    <div *ngIf="zone1Form.get('surfaceZone1').errors?.['pattern']">La surface n'est pas valide</div>
                  </div>
                </div>
                <div class="col-2">
                  <span class="spanTextMiddle">m²</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class='col-12'>
              <label for="azimutZone1" class="form-label">Azimut (compris entre -180 et 180, SUD = 0) <span class="required">*</span></label>
              <div class="row">
                <div class="col-10">
                  <input type="number" class="form-control" id="azimutZone1" formControlName="azimutZone1" placeholder="Ex : 100" tabindex="9"
                   [attr.disabled]="true"
                   [ngClass]="zone1Form.get('azimutZone1').invalid && (zone1Form.get('azimutZone1').dirty || zone1Form.get('azimutZone1').touched) ? 'is-invalid' : ''">
                  <div class="invalid-feedback">
                    <div *ngIf="zone1Form.get('azimutZone1').errors?.['required']">L'azimut est obligatoire</div>
                    <div *ngIf="zone1Form.get('azimutZone1').errors?.['pattern']">L'azimut n'est pas valide</div>
                  </div>
                </div>
                <div class="col-2">
                  <span class="spanTextMiddle">°</span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="card" *ngIf="zone1Form.get('typePoseZone1').value === 'TOITURE'">
      <div class="card-body">
        <h2 class="card-title">Toiture</h2>
        <div class="row">
          <div class="col-5">
              <label class="form-label" for="typeToitureZone1">Type toiture <span class="required">*</span></label>
              <select class="form-select" id="typeToitureZone1" formControlName="typeToitureZone1" tabindex="10"
                      [ngClass]="zone1Form.get('typeToitureZone1').invalid && (zone1Form.get('typeToitureZone1').dirty || zone1Form.get('typeToitureZone1').touched) ? 'is-invalid' : ''">
                <option value=""></option>
                <option *ngFor="let typeToitureZone1 of typeToitures" [ngValue]="typeToitureZone1.code"
                        [selected]="zone1Form.get('typeToitureZone1').value && typeToitureZone1.code === zone1Form.get('typeToitureZone1').value">
                  {{ typeToitureZone1.label }}
                </option>
              </select>
              <div class="invalid-feedback">
                <div *ngIf="zone1Form.get('typeToitureZone1').errors?.['required']">Le type de toiture est obligatoire</div>
              </div>
          </div>
          <div class="col-3">
            <div class="form-group form-check">
              <span class="spanCheckbox">PV Ready <span class="required">*</span></span>
              <input class="form-check-input" type="checkbox" formControlName="pvReadyZone1" tabindex="11"
                     [ngClass]="zone1Form.get('pvReadyZone1').invalid && (zone1Form.get('pvReadyZone1').dirty || zone1Form.get('pvReadyZone1').touched) ? 'is-invalid' : ''">
              <div class="invalid-feedback">
                <div *ngIf="zone1Form.get('pvReadyZone1').errors?.['required']">Cocher PV Ready</div>
              </div>
            </div>
          </div>
          <div class="col-4">
              <div class="row">
                <label for="inclinaisonZone1" class="form-label">Inclinaison <span class="required">*</span></label>
                <div class="col-10">
                  <input type="number" class="form-control" id="inclinaisonZone1" formControlName="inclinaisonZone1" placeholder="Ex : 100" tabindex="12"
                         [ngClass]="zone1Form.get('inclinaisonZone1').invalid && (zone1Form.get('inclinaisonZone1').dirty || zone1Form.get('inclinaisonZone1').touched) ? 'is-invalid' : ''">
                  <div class="invalid-feedback">
                    <div *ngIf="zone1Form.get('inclinaisonZone1').errors?.['pattern']">L'inclinaison n'est pas valide</div>
                    <div *ngIf="zone1Form.get('inclinaisonZone1').errors?.['required']">L'inclinaison est obligatoire</div>
                  </div>
                </div>
                <div class="col-1">
                  <span class="spanTextMiddle">°</span>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card" *ngIf="zone1Form.get('typePoseZone1').value === 'OMBRIERE'">
      <div class="card-body">
        <h2 class="card-title">Ombrière</h2>
        <div class="row">
          <div class="col-6">
            <label class="form-label" for="typeVehiculeParkingZone1">Type véhicule sur le parking <span class="required">*</span></label>
            <select class="form-select" id="typeVehiculeParkingZone1" formControlName="typeVehiculeParkingZone1" tabindex="13"
                    [ngClass]="zone1Form.get('typeVehiculeParkingZone1').invalid && (zone1Form.get('typeVehiculeParkingZone1').dirty || zone1Form.get('typeVehiculeParkingZone1').touched) ? 'is-invalid' : ''">
              <option value=""></option>
              <option *ngFor="let typeVehiculeParkingZone1 of typeParkings" [ngValue]="typeVehiculeParkingZone1.code"
                      [selected]="zone1Form.get('typeVehiculeParkingZone1').value && typeVehiculeParkingZone1.code === zone1Form.get('typeVehiculeParkingZone1').value">
                {{ typeVehiculeParkingZone1.label }}
              </option>
            </select>
            <div class="invalid-feedback">
              <div *ngIf="zone1Form.get('typeVehiculeParkingZone1').errors?.['required']">Le type véhicule sur le parking est obligatoire</div>
            </div>
          </div>
          <div class="col-6">
            <div *ngIf="zone1Form.get('typeVehiculeParkingZone1').value === 'VL'">
              <label class="form-label" for="typePlaceParkingZone1">Type de place sur le parking <span class="required">*</span></label>
              <select class="form-select" id="typePlaceParkingZone1" formControlName="typePlaceParkingZone1" tabindex="14"
                      [ngClass]="zone1Form.get('typePlaceParkingZone1').invalid && (zone1Form.get('typePlaceParkingZone1').dirty || zone1Form.get('typePlaceParkingZone1').touched) ? 'is-invalid' : ''">
                <option value=""></option>
                <option *ngFor="let typePlaceParkingZone1 of typePlaces" [ngValue]="typePlaceParkingZone1.code"
                        [selected]="zone1Form.get('typePlaceParkingZone1').value && typePlaceParkingZone1.code === zone1Form.get('typePlaceParkingZone1').value">
                  {{ typePlaceParkingZone1.label }}
                </option>
              </select>
              <div class="invalid-feedback">
                <div *ngIf="zone1Form.get('typePlaceParkingZone1').errors?.['required']">Le type de place sur le parking est obligatoire</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
