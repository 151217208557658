import { LOCALE_ID, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { NgbModalConfig, NgbModule, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap'
import { OAuthModule, OAuthService } from 'angular-oauth2-oidc'
import { authConfig } from './core/configuration/auth.config'
import { environment } from '../environments/environment'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks'
import { AuthInterceptor } from './core/interceptors/auth-interceptor'
import { ToastrModule } from 'ngx-toastr'
import { ErrorInterceptor } from './core/interceptors/error-interceptor'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import localeFr from '@angular/common/locales/fr'
import { registerLocaleData } from '@angular/common'
import { SunleonFormComponent } from './sunleon/feature/sunleon-form/sunleon-form.component'
import { NgSelectModule } from '@ng-select/ng-select'
import { ParametreComponent } from './parametre/feature/parametre/parametre.component'
import { ParametreOngletComponent } from './parametre/feature/parametre-onglet/parametre-onglet.component'
import { ParametreSimpleModalComponent } from './parametre/feature/modals/parametre-simple-modal/parametre-simple-modal.component'
import { ParametreTableauModalComponent } from './parametre/feature/modals/parametre-tableau-modal/parametre-tableau-modal.component'
import { ParametreSpecifiqueBddEnedisModalComponent } from './parametre/feature/modals/parametre-specifique-bdd-enedis-modal/parametre-specifique-bdd-enedis-modal.component'
import { AutocompleteSelectComponent } from './shared/ui/autocomplete-select/autocomplete-select.component'
import { AppLayoutComponent } from './core/feature/app-layout/app-layout.component'
import { FooterComponent } from './core/feature/footer/footer.component'
import { HeaderComponent } from './core/feature/header/header.component'
import { NotFoundComponent } from './core/feature/not-found/not-found.component'
import { UserNotAuthenticatedComponent } from './core/feature/user-not-authenticated/user-not-authenticated.component'
import { FormModeValorisationComponent } from './sungeo/feature/form-mode-valorisation/form-mode-valorisation.component'
import { FormProjetComponent } from './sungeo/feature/form-projet/form-projet.component'

registerLocaleData(localeFr)

@NgModule({
  declarations: [
    AppComponent,
    AppLayoutComponent,
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
    SunleonFormComponent,
    UserNotAuthenticatedComponent,
    ParametreComponent,
    ParametreOngletComponent,
    ParametreSimpleModalComponent,
    ParametreTableauModalComponent,
    ParametreSpecifiqueBddEnedisModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-container',
      closeButton: true,
      progressBar: true,
    }),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.baseUrl],
        sendAccessToken: false,
      },
    }),
    NgSelectModule,
    AutocompleteSelectComponent,
    FormModeValorisationComponent,
    FormProjetComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'fr' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // Configuration globale
  constructor(configModal: NgbModalConfig, configTooltip: NgbTooltipConfig, private oauthService: OAuthService) {
    configModal.centered = true

    configTooltip.container = 'body'
    configTooltip.openDelay = 600

    oauthService.configure(authConfig)
    oauthService.tokenValidationHandler = new JwksValidationHandler()
    oauthService.setStorage(localStorage)
  }
}
