<div class='col-12' style='margin-left: 5px;'>
    <div class='row'>
      <div class='col-4'>
        <div class='col-12'>
          <div class='row'>
            <div class='col-4'>
              <button [class.btn-erreur]="parentForm.get('zone1')?.invalid && ngForm.submitted"
                      [ngClass]="(bZone1Renseigne) ? 'onglet-form-button-active-zone1' : (ongletActif === ongletEnum.Zone1) ? 'onglet-form-button-active-zone1' : 'onglet-form-button-nonactive-zone1'"
                      class='btn onglet-form-button' (click)="onButtonZone1Click()">
                  <i [ngClass]="parentForm.get('zone1')?.invalid && ngForm.submitted ? 'bi-exclamation-circle-fill icon-btn-erreur' : ''"></i>
                Zone 1
              </button>
            </div>
            <div class='col-4'>
              <button [class.btn-erreur]="parentForm.get('zone2')?.invalid && ngForm.submitted"
                      [ngClass]="(bZone2Renseigne) ? 'onglet-form-button-active-zone2' : (ongletActif === ongletEnum.Zone2) ? 'onglet-form-button-active-zone2' : 'onglet-form-button-nonactive-zone2'"
                      class='btn onglet-form-button' (click)="onButtonZone2Click()">
                <i [ngClass]="parentForm.get('zone2')?.invalid && ngForm.submitted ? 'bi-exclamation-circle-fill icon-btn-erreur' : ''"></i>
                Zone 2
              </button>
            </div>
            <div class='col-4'>
              <button [class.btn-erreur]="parentForm.get('zone3')?.invalid && ngForm.submitted"
                      [ngClass]="(bZone3Renseigne) ? 'onglet-form-button-active-zone3' : (ongletActif === ongletEnum.Zone3) ? 'onglet-form-button-active-zone3' : 'onglet-form-button-nonactive-zone3'"
                      class='btn onglet-form-button' (click)="onButtonZone3Click()">
                <i [ngClass]="parentForm.get('zone3')?.invalid && ngForm.submitted ? 'bi-exclamation-circle-fill icon-btn-erreur' : ''"></i>
                Zone 3
              </button>
           </div>
          </div>
        </div>
      </div>
      <div class='col-2'>
        <button [class.btn-erreur]="parentForm.get('projet')?.invalid && ngForm.submitted"
                [ngClass]="(ongletActif === ongletEnum.Projet) ? 'onglet-form-button-active' : 'onglet-form-button-nonactive'"
                class='btn onglet-form-button' (click)="onButtonProjetClick()">
          <i [ngClass]="parentForm.get('projet')?.invalid && ngForm.submitted ? 'bi-exclamation-circle-fill icon-btn-erreur' : ''"></i>
          Projet
        </button>
      </div>
      <div class='col-2'>
        <button [class.btn-erreur]="parentForm.get('modevalorisation')?.invalid && ngForm.submitted"
                [ngClass]="(ongletActif === ongletEnum.ModeValorisation) ? 'onglet-form-button-active' : 'onglet-form-button-nonactive'"
                class='btn onglet-form-button' (click)="onButtonModeValorisationClick()">
          <i [ngClass]="parentForm.get('modevalorisation')?.invalid && ngForm.submitted ? 'bi-exclamation-circle-fill icon-btn-erreur' : ''"></i>
          Mode Valorisation
        </button>
      </div>
      <div class='col-2'>
        <button [class.btn-erreur]="parentForm.get('capexgeneralises')?.invalid && ngForm.submitted"
                [ngClass]="(ongletActif === ongletEnum.OptionsCapex) ? 'onglet-form-button-active' : 'onglet-form-button-nonactive'"
                class='btn onglet-form-button' (click)="onButtonOptionsCapexClick()">
          <i [ngClass]="parentForm.get('capexgeneralises')?.invalid && ngForm.submitted ? 'bi-exclamation-circle-fill icon-btn-erreur' : ''"></i>
          Options CAPEX
        </button>
      </div>
      <div class='col-2' [hidden]='!bSolOmbriere'>
        <button [class.btn-erreur]="parentForm.get('capextypepose')?.invalid && ngForm.submitted"
                [ngClass]="(ongletActif === ongletEnum.CapexTypePose) ? 'onglet-form-button-active' : 'onglet-form-button-nonactive'"
                class='btn onglet-form-button' (click)="onButtonCapexTypePoseClick()">
          <i [ngClass]="parentForm.get('capextypepose')?.invalid && ngForm.submitted ? 'bi-exclamation-circle-fill icon-btn-erreur' : ''"></i>
          CAPEX type pose
        </button>
      </div>
    </div>
</div>
<form #ngForm="ngForm" class='floating-form'>
  <app-form-projet [hidden]='ongletActif !== ongletEnum.Projet' (formReady)='addChildsFormGroup($event,"projet")'></app-form-projet>
  <app-form-mode-valorisation [hidden]='ongletActif !== ongletEnum.ModeValorisation' (formReady)='addChildsFormGroup($event,"modevalorisation")' ></app-form-mode-valorisation>
  <app-form-capex-generalises [hidden]='ongletActif !== ongletEnum.OptionsCapex' (formReady)='addChildsFormGroup($event,"capexgeneralises")' ></app-form-capex-generalises>
  <app-form-capex-type-pose [hidden]='ongletActif !== ongletEnum.CapexTypePose' (formReady)='addChildsFormGroup($event,"capextypepose")' [shouldDisplaySectionSol] = bSol [shouldDisplaySectionOmbriere] = bOmbriere ></app-form-capex-type-pose>
  <app-form-zone1 [hidden]='ongletActif !== ongletEnum.Zone1' (formReady)='addChildsFormGroup($event,"zone1")' ></app-form-zone1>
  <app-form-zone2 [hidden]='ongletActif !== ongletEnum.Zone2' (formReady)='addChildsFormGroup($event,"zone2")' ></app-form-zone2>
  <app-form-zone3 [hidden]='ongletActif !== ongletEnum.Zone3' (formReady)='addChildsFormGroup($event,"zone3")' ></app-form-zone3>
  <div class='container'>
    <div class='card'>
        <div class=row style='margin-right: 1px;'>
          <div class="bouton" >
            <button id='spinner-button' type='submit' class='btn btn-submit btn-primary'
                    (click)='saveSimulation()'
                    [disabled]='formIsSubmitting'
                    [disabled]='modalService.hasOpenModals()'>
              Lancer la simulation
              <div *ngIf='formIsSubmitting' class='col-3 text-center spinner-border text-success' role='status'></div>
            </button>
          </div>
        </div>
    </div>
  </div>
</form>
<app-control-panel *ngIf='bNouvelleSimulation'></app-control-panel>
<app-measure-display [hidden]='true' *ngIf='bNouvelleSimulation'></app-measure-display>

<ng-template #contentSuccess let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Simulation terminée</h5>
    <button type="button" class="btn-close btn-primary" aria-label="Close" (click)="modal.dismiss('Close click')">
    </button>
  </div>
  <div class="modal-body">
    <h5>{{ apiMessageSuccess }}</h5>
  </div>
  <div class="modal-footer d-flex flex-column align-items-stretch">
    <div class="row">
      <div class="col-7">
      </div>
      <div class="col-5">
        <button type="button" class="btn btn-modal btn-primary" (click)="modal.close('Close click')">Fermer</button>
      </div>
    </div>
  </div>
</ng-template>
