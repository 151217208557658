<nav class="navbar navbar-secondary-menu bg-light pb-0 ">
  <div class="container">
    <ul class="nav nav-pills nav-fill w-100 flex-nowrap align-items-center">
      <li class="nav-item" *ngFor="let familleDto of referenceFamille">
        <a class="nav-link px-0" [routerLink]="['/parametres/famille/', familleDto.code.toLowerCase()]" id="famille"
           routerLinkActive="active">
          {{familleDto.label}}
        </a>
      </li>
    </ul>
  </div>
</nav>
