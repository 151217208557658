import { Component, OnInit } from '@angular/core'
import { AuthService } from '../../services/auth.service'
import { User } from '../../../shared/models/user'
import { ParametreService } from '../../../parametre/data-access/http/parametre.service'
import { environment } from '../../../../environments/environment'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  user: User
  famille: string
  envtest:string

  constructor(
    public authService: AuthService,
    public paramService: ParametreService
) {}

  ngOnInit(): void {
    this.user = this.authService.connectedUser
    this.famille = this.paramService.familleParDefaut;
    this.envtest = environment.name.toUpperCase() === 'PROD' ? '' : environment.name.toUpperCase();
  }

}
