<div [formGroup]="zone3Form">
  <div class='container'>
    <div class="card">
      <div class="card-body">
        <h2 class="card-title">Zone n°3</h2>
        <div class="row">
          <div class='col-12'>
            <label class="form-label" >Type de pose</label>
            <div class="btn-group d-block" role="group">
              <ng-container *ngFor="let typePose of typePoses">
                <input type="radio" formControlName="typePoseZone3" class="btn-check btn-type-pose-options" [value]="typePose.code" [id]="typePose.code + 3"
                       (change)='onRadioClickZone3(typePose.code, "typePoseZone3")' tabindex="1">
                <label class="btn btn-outline-success btn-type-pose-options" [for]="typePose.code + 3"> {{ typePose.label }}</label>
              </ng-container>
            </div>
          </div>
        </div>
        <ng-container *ngIf="zone3Form.get('typePoseZone3').value">
          <div class="row">
            <div class='col-12'>
              <label for="surfaceZone3" class="form-label">Surface <span class="required">*</span></label>
              <div class="row">
                <div class="col-10">
                  <input type="number" class="form-control" id="surfaceZone3" formControlName="surfaceZone3" placeholder="Ex : 45.32" tabindex="8"
                     [attr.disabled]="true"
                     [ngClass]="zone3Form.get('surfaceZone3').invalid && (zone3Form.get('surfaceZone3').dirty || zone3Form.get('surfaceZone3').touched) ? 'is-invalid' : ''">
                  <div class="invalid-feedback">
                    <div *ngIf="zone3Form.get('surfaceZone3').errors?.['required']">La surface est obligatoire</div>
                    <div *ngIf="zone3Form.get('surfaceZone3').errors?.['pattern']">La surface n'est pas valide</div>
                  </div>
                </div>
                <div class="col-2">
                  <span class="spanTextMiddle">m²</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class='col-12'>
              <label for="azimutZone3" class="form-label">Azimut (compris entre -180 et 180, SUD = 0) <span class="required">*</span></label>
              <div class="row">
                <div class="col-10">
                  <input type="number" class="form-control" id="azimutZone3" formControlName="azimutZone3" placeholder="Ex : 100" tabindex="9"
                   [attr.disabled]="true"
                   [ngClass]="zone3Form.get('azimutZone3').invalid && (zone3Form.get('azimutZone3').dirty || zone3Form.get('azimutZone3').touched) ? 'is-invalid' : ''">
                  <div class="invalid-feedback">
                    <div *ngIf="zone3Form.get('azimutZone3').errors?.['required']">L'azimut est obligatoire</div>
                    <div *ngIf="zone3Form.get('azimutZone3').errors?.['pattern']">L'azimut n'est pas valide</div>
                  </div>
                </div>
                <div class="col-2">
                  <span class="spanTextMiddle">°</span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="card" *ngIf="zone3Form.get('typePoseZone3').value === 'TOITURE'">
      <div class="card-body">
        <h2 class="card-title">Toiture</h2>
        <div class="row">
          <div class="col-5">
            <label class="form-label" for="typeToitureZone3">Type toiture <span class="required">*</span></label>
            <select class="form-select" id="typeToitureZone3" formControlName="typeToitureZone3" tabindex="10"
                    [ngClass]="zone3Form.get('typeToitureZone3').invalid && (zone3Form.get('typeToitureZone3').dirty || zone3Form.get('typeToitureZone3').touched) ? 'is-invalid' : ''">
              <option value=""></option>
              <option *ngFor="let typeToitureZone3 of typeToitures" [ngValue]="typeToitureZone3.code"
                      [selected]="zone3Form.get('typeToitureZone3').value && typeToitureZone3.code === zone3Form.get('typeToitureZone3').value">
                {{ typeToitureZone3.label }}
              </option>
            </select>
            <div class="invalid-feedback">
              <div *ngIf="zone3Form.get('typeToitureZone3').errors?.['required']">Le type de toiture est obligatoire</div>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group form-check">
              <span class="spanCheckbox">PV Ready <span class="required">*</span></span>
              <input class="form-check-input" type="checkbox" formControlName="pvReadyZone3" tabindex="11"
                     [ngClass]="zone3Form.get('pvReadyZone3').invalid && (zone3Form.get('pvReadyZone3').dirty || zone3Form.get('pvReadyZone3').touched) ? 'is-invalid' : ''">
              <div class="invalid-feedback">
                <div *ngIf="zone3Form.get('pvReadyZone3').errors?.['required']">Cocher PV Ready</div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <label for="inclinaisonZone3" class="form-label">Inclinaison <span class="required">*</span></label>
              <div class="col-10">
                <input type="number" class="form-control" id="inclinaisonZone3" formControlName="inclinaisonZone3" placeholder="Ex : 100" tabindex="12"
                       [ngClass]="zone3Form.get('inclinaisonZone3').invalid && (zone3Form.get('inclinaisonZone3').dirty || zone3Form.get('inclinaisonZone3').touched) ? 'is-invalid' : ''">
                <div class="invalid-feedback">
                  <div *ngIf="zone3Form.get('inclinaisonZone3').errors?.['pattern']">L'inclinaison n'est pas valide</div>
                  <div *ngIf="zone3Form.get('inclinaisonZone3').errors?.['required']">L'inclinaison est obligatoire</div>
                </div>
              </div>
              <div class="col-1">
                <span class="spanTextMiddle">°</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card" *ngIf="zone3Form.get('typePoseZone3').value === 'OMBRIERE'">
      <div class="card-body">
        <h2 class="card-title">Ombrière</h2>
        <div class="row">
          <div class="col-6">
            <label class="form-label" for="typeVehiculeParkingZone3">Type véhicule sur le parking <span class="required">*</span></label>
            <select class="form-select" id="typeVehiculeParkingZone3" formControlName="typeVehiculeParkingZone3" tabindex="13"
                    [ngClass]="zone3Form.get('typeVehiculeParkingZone3').invalid && (zone3Form.get('typeVehiculeParkingZone3').dirty || zone3Form.get('typeVehiculeParkingZone3').touched) ? 'is-invalid' : ''">
              <option value=""></option>
              <option *ngFor="let typeVehiculeParkingZone3 of typeParkings" [ngValue]="typeVehiculeParkingZone3.code"
                      [selected]="zone3Form.get('typeVehiculeParkingZone3').value && typeVehiculeParkingZone3.code === zone3Form.get('typeVehiculeParkingZone3').value">
                {{ typeVehiculeParkingZone3.label }}
              </option>
            </select>
            <div class="invalid-feedback">
              <div *ngIf="zone3Form.get('typeVehiculeParkingZone3').errors?.['required']">Le type véhicule sur le parking est obligatoire</div>
            </div>
          </div>
          <div class="col-6">
            <div *ngIf="zone3Form.get('typeVehiculeParkingZone3').value === 'VL'">
              <label class="form-label" for="typePlaceParkingZone3">Type de place sur le parking <span class="required">*</span></label>
              <select class="form-select" id="typePlaceParkingZone3" formControlName="typePlaceParkingZone3" tabindex="14"
                      [ngClass]="zone3Form.get('typePlaceParkingZone3').invalid && (zone3Form.get('typePlaceParkingZone3').dirty || zone3Form.get('typePlaceParkingZone3').touched) ? 'is-invalid' : ''">
                <option value=""></option>
                <option *ngFor="let typePlaceParkingZone3 of typePlaces" [ngValue]="typePlaceParkingZone3.code"
                        [selected]="zone3Form.get('typePlaceParkingZone3').value && typePlaceParkingZone3.code === zone3Form.get('typePlaceParkingZone3').value">
                  {{ typePlaceParkingZone3.label }}
                </option>
              </select>
              <div class="invalid-feedback">
                <div *ngIf="zone3Form.get('typePlaceParkingZone3').errors?.['required']">Le type de place sur le parking est obligatoire</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
