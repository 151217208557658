import { AfterViewInit, Component, EventEmitter, inject, OnInit, Output } from '@angular/core'
import { NgClass, NgForOf, NgIf } from '@angular/common'
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { TypeReferenceDto } from '../../../sunleon/data-access/http/dto/type-reference-dto'
import { ReferenceService } from '../../../sunleon/data-access/http/reference.service'
import { ZoneMeasures } from '../../model/zone-measures'
import { GeomapDomainService } from '../../domain/geomap.domain'
import { CustomValidators } from '../../../shared/validators/custom-validators'

@Component({
  selector: 'app-form-zone3',
  templateUrl: './form-zone3.component.html',
  styleUrls: ['./form-zone3.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, NgForOf, ReactiveFormsModule, NgClass],
})
export class FormZone3Component implements OnInit, AfterViewInit {
  @Output()
  formReady: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  private readonly geomapDomainService = inject(GeomapDomainService)
  readonly measuresZone3$ = this.geomapDomainService.getAllMeasuresForZone$(2)

  zone3Form: FormGroup
  typePoses: TypeReferenceDto[] = []
  typeToitures: TypeReferenceDto[] = []
  typePlaces: TypeReferenceDto[] = []
  typeParkings: TypeReferenceDto[] = []

  onRadioClickZone3(code: string | null, controleName: string) {
    this.zone3Form.get(controleName).setValue(code)
    this.geomapDomainService.selectTypePoseOutils(2)
    this.geomapDomainService.selectionBarreOutils()
    this.geomapDomainService.selectionDessinOutilsSeulementZone()
  }

  ngAfterViewInit(): void {
    this.measuresZone3$.subscribe((zoneMeasures: ZoneMeasures) => {
      this.zone3Form.get('surfaceZone3').patchValue(zoneMeasures.surface === 0 ? null : zoneMeasures.surface)
      this.zone3Form.get('azimutZone3').patchValue(zoneMeasures.azimuth)
    })
  }

  constructor(private formBuilder: FormBuilder, private referenceService: ReferenceService) {
    this.zone3Form = this.formBuilder.group({
      typePoseZone3: [null],
      surfaceZone3: [null, [Validators.pattern('^\\d+(\\.\\d{1,2})?$')]],
      azimutZone3: [null, [Validators.pattern('^-?(180|\\d{1,2}|1[0-7]\\d)$')]],
      typeToitureZone3: [''],
      pvReadyZone3: [false],
      inclinaisonZone3: [null, [Validators.pattern('^\\d+$')]],
      typeVehiculeParkingZone3: [''],
      typePlaceParkingZone3: [''],
    })

    this.initTypePoseZone(
      'typePoseZone3',
      'deposeCandelabre',
      'poseCandelabre',
      'eclairageSousOmbriere',
      'nbAbattageArbres',
      'mlClotureSol',
      'nbPortailsSol',
      'surfaceZone3',
      'azimutZone3',
      'typeToitureZone3',
      'pvReadyZone3',
      'inclinaisonZone3',
      'typeVehiculeParkingZone3',
      'typePlaceParkingZone3'
    )
  }

  ngOnInit(): void {
    this.referenceService.getTypePose().subscribe((data) => {
      this.typePoses = data
    })
    this.referenceService.getTypeToiture().subscribe((data) => {
      this.typeToitures = data
    })
    this.referenceService.getTypeParking().subscribe((data) => {
      this.typeParkings = data
    })
    this.referenceService.getTypePlace().subscribe((data) => {
      this.typePlaces = data
    })
    this.formReady.emit(this.zone3Form)
  }

  initTypePoseZone(
    typePose: string,
    deposeCandelabre: string,
    poseCandelabre: string,
    eclairageSousOmbriere: string,
    nbAbattageArbres: string,
    mlClotureSol: string,
    nbPortailsSol: string,
    surfaceZone: string,
    azimutZone: string,
    typeToitureZone: string,
    pvReadyZone: string,
    inclinaisonZone: string,
    typeVehiculeParkingZone: string,
    typePlaceParkingZone: string
  ) {

    this.zone3Form.get(typePose).valueChanges.subscribe((typePoseZone) => {
      const constSurfaceZone = this.zone3Form.get(surfaceZone)
      const constAzimutZone = this.zone3Form.get(azimutZone)
      const constTypeToitureZone = this.zone3Form.get(typeToitureZone)
      const constPvReadyZone = this.zone3Form.get(pvReadyZone)
      const constInclinaisonZone = this.zone3Form.get(inclinaisonZone)
      const constTypeVehiculeParkingZone = this.zone3Form.get(typeVehiculeParkingZone)
      const constTypePlaceParkingZone = this.zone3Form.get(typePlaceParkingZone)

      if (typePoseZone) {
        constSurfaceZone.setValidators([
          CustomValidators.requiredIfControlHasValue(typePose),
          Validators.pattern('^\\d+(\\.\\d{1,2})?$'),
        ])
        constAzimutZone.setValidators([
          CustomValidators.requiredIfControlHasValue(typePose),
          Validators.pattern('^-?(180|\\d{1,2}|1[0-7]\\d)$'),
        ])

        if (typePoseZone === 'TOITURE') {
          constTypeToitureZone.setValidators([CustomValidators.requiredIfControlHasValue(typePose)])
          constPvReadyZone.setValidators([CustomValidators.requiredIfControlHasValue(typePose)])
          constInclinaisonZone.setValidators([CustomValidators.requiredIfControlHasValue(typePose)])
          constTypeToitureZone.reset()
          constPvReadyZone.reset()
          constInclinaisonZone.reset()

          constTypeVehiculeParkingZone.setValidators([Validators.nullValidator])
          constTypePlaceParkingZone.setValidators([Validators.nullValidator])
          constTypeVehiculeParkingZone.reset()
          constTypePlaceParkingZone.reset()
        }
        if (typePoseZone === 'OMBRIERE') {
          constTypeVehiculeParkingZone.setValidators([CustomValidators.requiredIfControlHasValue(typePose)])
          constTypeVehiculeParkingZone.reset()
          constTypePlaceParkingZone.reset()

          constTypeToitureZone.setValidators([Validators.nullValidator])
          constPvReadyZone.setValidators([Validators.nullValidator])
          constInclinaisonZone.setValidators([Validators.nullValidator])

          constTypeToitureZone.reset()
          constPvReadyZone.reset()
          constInclinaisonZone.reset()
        }

        if (typePoseZone === 'SOL') {
          constTypeToitureZone.setValidators([Validators.nullValidator])
          constPvReadyZone.setValidators([Validators.nullValidator])
          constInclinaisonZone.setValidators([Validators.nullValidator])
          constTypeVehiculeParkingZone.setValidators([Validators.nullValidator])
          constTypePlaceParkingZone.setValidators([Validators.nullValidator])

          constTypeToitureZone.reset()
          constPvReadyZone.reset()
          constInclinaisonZone.reset()
          constTypeVehiculeParkingZone.reset()
          constTypePlaceParkingZone.reset()
        }
      } else {
        constSurfaceZone.setValidators([Validators.pattern('^\\d+(\\.\\d{1,2})?$')])
        constAzimutZone.setValidators([Validators.pattern('^-?(180|\\d{1,2}|1[0-7]\\d)$')])
        constTypeToitureZone.setValidators([Validators.nullValidator])
        constPvReadyZone.setValidators([Validators.nullValidator])
        constInclinaisonZone.setValidators([Validators.nullValidator])
        constTypeVehiculeParkingZone.setValidators([Validators.nullValidator])
        constTypePlaceParkingZone.setValidators([Validators.nullValidator])

        constSurfaceZone.reset()
        constAzimutZone.reset()
        constTypeToitureZone.reset()
        constPvReadyZone.reset()
        constInclinaisonZone.reset()
        constTypeVehiculeParkingZone.reset()
        constTypePlaceParkingZone.reset()
      }
    })

    this.zone3Form.get(typeVehiculeParkingZone).valueChanges.subscribe((typeVehiculeParking) => {
      const constTypeVehiculeParkingZone = this.zone3Form.get(typeVehiculeParkingZone)
      const constTypePlaceParkingZone = this.zone3Form.get(typePlaceParkingZone)
      constTypeVehiculeParkingZone.setValidators([CustomValidators.requiredIfControlHasValue(typePose)])
      if (typeVehiculeParking === 'VL') {
        constTypePlaceParkingZone.setValidators([CustomValidators.requiredIfControlHasValue(typePose)])
      } else {
        constTypePlaceParkingZone.setValidators([Validators.nullValidator])
        constTypePlaceParkingZone.reset()
      }
    })

  }
}

