<div class="modal-header">
  <h5 class="modal-title">{{parametre.code}}</h5>
  <button type="button" class="btn-close btn-primary" aria-label="Close" (click)='fermerModal()'>
  </button>
</div>

<div class="modal-body">
  <div class="parametre-form">
    <div class="row">
      <div class="mb-2">
        <label><span>{{parametre.desc}}</span></label><label><span class="required">*</span></label>
      </div>
    </div>
    <div class="table-responsive w-100">
      <table *ngIf="displayTableau" class="table table-bordered table-striped" [formGroup]='formGroup'>
        <thead class="thead-dark sticky-top">
          <tr formArrayName='titres'>
            <th scope="col">
              {{parametreTableauDto.tableautitre}}
            </th>
            <th scope="col">
              {{parametreTableauDto.tableautitreunite}}
            </th>
            <th *ngFor="let item of parametreTableauDto.colonnes; let i = index">
              <input type="number" class="form-control" [formControlName]="i" placeholder="Ex : 100">
              <div *ngIf="isInvalidTranche(i)" class="invalid" >{{ getValidationMessage(i) }}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of parametreTableauDto.titreLignes; let i = index" formArrayName='datas'>
            <td>{{ item }}</td>
            <td *ngIf="parametreTableauDto.titreUniteLignes && parametreTableauDto.titreUniteLignes[i]">
              {{ parametreTableauDto.titreUniteLignes[i] }}
            </td>
            <ng-container [formArrayName]='i'>
            <td *ngFor="let item of parametreTableauDto.colonnes; let j = index">
              <ng-container *ngIf="parametre.typevaleur.toLowerCase() !== 'enum'">
                <input type="number" class="form-control" [formControlName]="j" placeholder="Ex : 100">
                <div *ngIf="isInvalidDatas(i,j)" class="invalid">La valeur est obligatoire</div>
              </ng-container>
              <ng-container *ngIf="parametre.typevaleur.toLowerCase() === 'enum'">
                <select class="form-select" [formControlName]="j">
                  <option disabled='disabled' *ngFor="let tarifAchat of tarifsAchat" [ngValue]="tarifAchat.code">
                    {{ tarifAchat.label }}
                  </option>
                </select>
              </ng-container>
            </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="modal-footer d-flex flex-column align-items-stretch">
  <div class="row">
    <div class="col-9">
      <button type="button" class="btn btn-modal btn-danger" (click)='fermerModal()'>Fermer</button>
    </div>
    <div class="col-3">
      <button type="submit" class="btn btn-modal btn-primary" [disabled]="formGroup.invalid" (click)='onSubmit()' >Valider
        <div *ngIf="formModalIsSubmitting" class="col-3 text-center spinner-border text-success" role="status"></div>
      </button>
    </div>
  </div>
</div>
