<div>
  <div class='floating-bar'>
    <div class='row'>
      <div class='col-5'>
        <div class='p-3' style='cursor: default'>
            <app-control-adresse></app-control-adresse>
        </div>
      </div>
      <div class='col-6' *ngIf='!bNouvelleSimulation'></div>
      <div class='col-1' *ngIf='!bNouvelleSimulation'>
        <div [hidden]='false' class='nouvelle-simulation' style='text-align: right'>
          <a (click)='onButtonClick()' title='Nouvelle simulation'>
            <span class="bi bi-file-earmark-plus-fill"></span>
          </a>
        </div>
        <div  [hidden]='true' class='nouvelle-simulation' style='text-align: right'>
          <button class='btn btn-submit btn-primary' (click)="onButtonClick()">Nouvelle simulation</button>
        </div>
      </div>
      <app-form-parent class='col-7' style='overflow: hidden; margin-top: 10px;' [bNouvelleSimulation]=bNouvelleSimulation *ngIf='bNouvelleSimulation'> </app-form-parent>
    </div>
  </div>
  <div #tooltip class='measure-tooltip'></div>
  <app-map class='d-flex flex-column align-items-center' [map]='geoMap'></app-map>
</div>
