import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { MapUtils } from '../utils/map.utils'

export class Zone {
    vectorLayer: VectorLayer<VectorSource>
    colorSurface: string
    color: string

    constructor(colorSurface: string, color:string) {
        this.color = color
        this.colorSurface = colorSurface
        this.vectorLayer = MapUtils.buildVectorLayer(color, colorSurface)
    }
}
