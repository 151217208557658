<div class='floating-bouton' [hidden]="(selectedOutils$ | async) === false">
  <div class="btn-group-vertical" role="group" aria-label="Groupe de boutons en colonne">
    <button
      [hidden]="((activeZoneId$ | async) < 3 && (selectedTypePoseCurrentZone$ | async) === false)
                || ((activeZoneId$ | async) === 3 && (selectedModeValorisation$ | async) === false)"
      (click)='disableTools()'
      [ngClass]="(activeTool$ | async) === toolEnum.None ? 'btn-primary' : 'btn-secondary'"
      class='btn custom-outils'
      title='Modifier ou voir les desssins'>
        <i class="bi bi-eye-fill custom-outils-bi"></i>
    </button>
    <button
      [hidden]="(selectedDessinOutilsSeulementZone$| async) === false || (selectedTypePoseCurrentZone$ | async) === false"
      (click)='activateMeasureAreaTool()'
      [ngClass]="(activeTool$ | async) === toolEnum.Surface ? 'btn-primary' : 'btn-secondary'"
      class='btn custom-outils'
      title='Dessiner la ou les surface(s)'>
        <i class="bi bi-square custom-outils-bi"></i>
    </button>
    <button
      [hidden]="(selectedDessinOutilsSeulementZone$| async) === false  || (selectedTypePoseCurrentZone$ | async) === false"
      (click)='activateMeasureAzimuthTool()'
      [ngClass]="(activeTool$ | async) === toolEnum.Azimuth ? 'btn-primary' : 'btn-secondary'"
      class='btn custom-outils'
      title="Dessiner l'azimuth">
        <i class="bi bi-compass custom-outils-bi"></i>
    </button>
    <button
      [hidden]="(selectedDessinOutilsSeulementZone$| async) === true || (selectedModeValorisation$ | async) === false"
      (click)='activateMeasureDistanceTool()'
      [ngClass]="(activeTool$ | async) === toolEnum.Length ? 'btn-primary' : 'btn-secondary'"
      class='btn custom-outils'
      title='Dessiner la ou les distance(s) de raccordement'>
        <i class="bi bi-arrows custom-outils-bi"></i>
    </button>
    <button
      [hidden]="((activeZoneId$ | async) < 3 && (selectedTypePoseCurrentZone$ | async) === false)
                || ((activeZoneId$ | async) === 3 && (selectedModeValorisation$ | async) === false)"
      (click)='activateDeleteTool()'
      [ngClass]="(activeTool$ | async) === toolEnum.Delete ? 'btn-primary' : 'btn-secondary'"
      class='btn custom-outils'
      title='Supprimer le ou les dessin(s)'>
        <i class="bi bi-trash custom-outils-bi"></i>
    </button>
    <button
      [hidden]="(((activeZoneId$ | async) < 3 && (selectedTypePoseCurrentZone$ | async) === false)
                || (activeZoneId$ | async) === 3)"
      (click)='resetCurrentZone()'
      class='btn btn-reset custom-outils'
      title='Suppression du type de pose sélectionné avec suppression de tous les dessins'>
        <i class="bi bi-x-lg custom-outils-bi"></i>
    </button>
    <button
      [hidden]="((activeZoneId$ | async) < 3)
                || ((activeZoneId$ | async) === 3 && (selectedModeValorisation$ | async) === false)"
      (click)='resetDessinModeValorisation()'
      class='btn btn-reset custom-outils'
      title='Suppression de tous les raccordements dessinés'>
      <i class="bi bi-x-lg custom-outils-bi"></i>
    </button>
  </div>
</div>
