import { Component, inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { GeomapDomainService } from '../../domain/geomap.domain'

@Component({
  selector: 'app-measure-display',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './measure-display.component.html',
  styleUrls: ['./measure-display.component.scss']
})
export class MeasureDisplayComponent {

  private readonly geomapDomainService = inject(GeomapDomainService)
  readonly measuresZone1$ = this.geomapDomainService.getAllMeasuresForZone$(0)
  readonly measuresZone2$ = this.geomapDomainService.getAllMeasuresForZone$(1)
  readonly measuresZone3$ = this.geomapDomainService.getAllMeasuresForZone$(2)
  readonly gpsData$ = this.geomapDomainService.getGpsData$()
  readonly distance$ = this.geomapDomainService.getDistance$()

}
