import {Router} from "@angular/router";

export function scrollToInvalidElement() {
  setTimeout(() => {
    const isInvalidElements = document.getElementsByClassName('is-invalid')
    if (isInvalidElements.length > 0) {
      const firstInvalidElement = isInvalidElements[0]
      const positionYToScroll = firstInvalidElement.getBoundingClientRect().top + window.scrollY - 120
      window.scrollTo({ top: positionYToScroll, behavior: 'smooth' })
    }
  }, 100)
}

export function reloadRoute(router: Router, route: any[]) {
  router.routeReuseStrategy.shouldReuseRoute = () => false
  router.onSameUrlNavigation = 'reload'
  router.navigate(route)
}

