import VectorSource from 'ol/source/Vector'
import { Draw, Select } from 'ol/interaction'
import { ToolEnum } from '../model/tool.enum'
import { StyleUtils } from './style.utils'
import { Feature } from 'ol'

export class InteractionUtils {

    static buildDeleteInteraction(vectorSource: VectorSource) {
        const deleteInteraction = new Select()
        deleteInteraction.on('select', event => {
            const selectedFeature = event.target.getFeatures().item(0)
            vectorSource.removeFeature(selectedFeature)
        })
        return deleteInteraction
    }

    static buildLengthInteraction(vectorSource: VectorSource, color: string): Draw {
        const lengthDraw = new Draw({
            source: vectorSource,
            maxPoints: null,
            type: 'LineString',
            style: (feature) => {
                const geometryType = feature.getGeometry().getType()
                if (geometryType === 'LineString' || geometryType === 'Point') {
                    return StyleUtils.buildDefaultStyleFromColor(color)
                }
                return null
            }
        })
        lengthDraw.on('drawstart', event => {
            event.feature.getGeometry().setProperties({ type: ToolEnum.Length })
        })
        return lengthDraw
    }

    static buildAzimuthInteraction(vectorSource: VectorSource, color: string): Draw {
        const azimuthDraw = new Draw({
            source: vectorSource,
            maxPoints: 2,
            type: 'LineString',
            style: (feature) => [StyleUtils.buildDefaultStyleFromColor(color), ...StyleUtils.buildAzimuthArrowStyle(feature as Feature)]

        })
        azimuthDraw.on('drawstart', event => {
            event.feature.getGeometry().setProperties({ type: ToolEnum.Azimuth })
            const featuresToKeep = vectorSource.getFeatures().filter(feature => feature.getGeometry().getProperties()['type'] !== ToolEnum.Azimuth)
            vectorSource.clear()
            featuresToKeep.forEach(feature => vectorSource.addFeature(feature))
        })
        return azimuthDraw
    }

    static buildAreaInteraction(vectorSource: VectorSource, color: string): Draw {
        const areaDraw = new Draw({
            source: vectorSource,
            maxPoints: null,
            type: 'Polygon',
            style: (feature) => {
                const geometryType = feature.getGeometry().getType()
                if (geometryType === 'Polygon' || geometryType === 'Point') {
                    return StyleUtils.buildDefaultStyleFromColor(color)
                }
                return null
            }
        })
        areaDraw.on('drawstart', event => {
            event.feature.getGeometry().setProperties({ type: ToolEnum.Surface })
        })
        return areaDraw
    }
}
