import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { HomeComponent } from './sungeo/feature/home/home.component'
import { AuthGuard } from './core/guards/auth.guard'
import { ParametreComponent } from './parametre/feature/parametre/parametre.component'
import { adminGuard } from './core/guards/admin.guard'
import { SunleonFormComponent } from './sunleon/feature/sunleon-form/sunleon-form.component'
import { AppLayoutComponent } from './core/feature/app-layout/app-layout.component'
import { NotFoundComponent } from './core/feature/not-found/not-found.component'
import { UserNotAuthenticatedComponent } from './core/feature/user-not-authenticated/user-not-authenticated.component'

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: '', pathMatch: 'full', component: HomeComponent},
      { path: 'sunleon', component: SunleonFormComponent },
      { path: 'not-found', component: NotFoundComponent },
      { path: 'user-not-authenticated', component: UserNotAuthenticatedComponent },
      { path: 'parametres/famille/:famille', component: ParametreComponent, canActivate: [adminGuard()] },
      { path: 'sungeo', loadChildren: () => import('./sungeo/feature/sungeo.routes').then((m) => m.ROUTES)},
    ],
  },
  { path: '**', redirectTo: 'not-found' },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
