<div class="modal-header">
  <h5 class="modal-title">{{parametre.code}}</h5>
  <button type="button" class="btn-close btn-primary" aria-label="Close" (click)='fermerModal()'>
  </button>
</div>
<div class="modal-body">
  <div class="parametre-form">
    <div class="row">
      <div class="mb-2">
        <label><span>{{parametre.desc}}</span></label>  <label><span class="required">*</span></label>
      </div>
    </div>
    <div class="row">
        <div class="col-6">
          <input #inputValeur class="form-control" [formControl]="control" type="number" tabindex="0"
                 [ngClass]="control.invalid && (control.dirty ||control.touched) ? 'is-invalid' : ''">
          <div class="invalid-feedback">
            <div *ngIf="control.errors?.['required']">Paramètre obligatoire</div>
            <div *ngIf="control.hasError('pattern') && parametre.typevaleur.toLowerCase() === 'entier'"> La valeur doit être un nombre entier non négatif.</div>
            <div *ngIf="control.hasError('pattern') && parametre.typevaleur.toLowerCase() === 'pourcentage'"> La valeur doit être compris entre 0 et 100.</div>
          </div>
        </div>
        <div class="col-6 px-4">
          <span class="spanTextMiddle">{{parametre.unite}} ({{parametre.typevaleur}})</span>
        </div>
    </div>
  </div>
</div>
<div class="modal-footer d-flex flex-column align-items-stretch">
  <div class="row">
    <div class="col-9">
      <button type="button" class="btn btn-modal btn-danger" (click)='fermerModal()'>Fermer</button>
    </div>
    <div class="col-3">
      <button type="submit" class="btn btn-modal btn-primary" [disabled]="control.invalid" (click)='onSubmit()' >Valider
        <div *ngIf="formModalIsSubmitting" class="col-3 text-center spinner-border text-success" role="status"></div>
      </button>
    </div>
  </div>
</div>
