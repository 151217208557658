import { LineString, Point } from 'ol/geom'
import { Fill, Stroke, Style } from 'ol/style.js'
import CircleStyle from 'ol/style/Circle'
import { Feature } from 'ol'
import { ToolEnum } from '../model/tool.enum'
import { Icon } from 'ol/style'

export class StyleUtils {
    static buildDefaultStyleFromColor(color: string): Style {
        return new Style({
            fill: new Fill({
                color: color

            }),
            stroke: new Stroke({
                color: 'rgba(0, 0, 0, 0.5)',
                lineDash: [10, 10],
                width: 5
            }),
            image: new CircleStyle({
                radius: 5,
                stroke: new Stroke({
                    color: 'rgba(0, 0, 0, 0.7)'
                }),
                fill: new Fill({
                    color: color
                })
            })
        })
    }

    static buildAzimuthArrowStyle(feature: Feature): Style[] {
        const styles: Style[] = []
        const geometryType = feature.getGeometry().getProperties()['type']

        if (geometryType === ToolEnum.Azimuth) {
            (feature.getGeometry() as LineString).forEachSegment(function(start, end) {
                const dx = end[0] - start[0]
                const dy = end[1] - start[1]
                const rotation = Math.atan2(dy, dx)
                // arrow
                styles.push(
                    new Style({
                        geometry: new Point(end),
                        image: new Icon({
                            src: 'assets/images/img/right-arrow-angle.svg',
                            anchor: [0.75, 0.5],
                            rotateWithView: true,
                            rotation: -rotation,
                            scale: 0.05
                        }),
                    })
                )
            })
        }
        return styles
    }
}

