import { Geometry, LineString, Polygon } from 'ol/geom'
import { ToolEnum } from '../model/tool.enum'
import { MeasureUtils } from './measure.utils'
import { Coordinate } from 'ol/coordinate'

export class TooltipUtils {
    static getTooltipLabel(geometry: Geometry) {
        const geometryType = geometry.getProperties()['type']
        if (geometryType === ToolEnum.Surface) {
            return MeasureUtils.computeArea(geometry as Polygon) + 'm<sup>2</sup>'
        }
        if (geometryType === ToolEnum.Azimuth) {
            return MeasureUtils.computeAzimuth(geometry as LineString) + '°'
        }
        if (geometryType === ToolEnum.Length) {
            return MeasureUtils.computeLength(geometry as LineString) + 'm'
        }
        return ''
    }

    static getTooltipPosition(geometry: Geometry): Coordinate {
        const geometryType = geometry.getProperties()['type']
        if (geometryType === ToolEnum.Surface) {
            return (geometry as Polygon).getInteriorPoint().getCoordinates()
        }
        if (geometryType === ToolEnum.Azimuth || geometryType === ToolEnum.Length) {
            return (geometry as LineString).getFlatMidpoint()
        }
        return null
    }
}
