<div class="modal-header">
  <h5 class="modal-title">{{parametre.code}}</h5>
  <button type="button" class="btn-close btn-primary" aria-label="Close" (click)='fermerModal()'>
  </button>
</div>

<div class="modal-body">
  <div class="parametre-form">
    <div class="row">
      <div class="mb-2">
        <label><span>{{parametre.desc}}</span></label><label><span class="required">*</span></label>
      </div>
    </div>
    <div class="table-responsive w-100">
      <table *ngIf="displayTableau" class="table table-bordered table-striped" [formGroup]='formGroup'>
        <thead class="thead-dark sticky-top">
        <tr>
          <th scope="col">
            Région
          </th>
          <th scope="col">
            Code département
          </th>
          <th scope="col">
            Nom département
          </th>
          <th scope="col">
            Quote-Part (k€/Mk)
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of parametreTableauDto.lignes; let i = index" formArrayName='datas'>
          <th scope="row">
            <!-- Vérifiez si le champ region est différent de la ligne précédente -->
            <ng-container *ngIf="i === 0 || item.region !== parametreTableauDto.lignes[i - 1].region">
              {{ item.region }}
            </ng-container>
          </th>
          <td>{{ item.codedepartement }}</td>
          <td>{{ item.nomdepartement }}</td>
          <td>
              <input type="number" class="form-control" [formControlName]="i" placeholder="Ex : 100">
            <div *ngIf="isInvalidQuotePart(i)" class="invalid" >{{ getValidationMessage(i) }}</div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="modal-footer d-flex flex-column align-items-stretch">
  <div class="row">
    <div class="col-9">
      <button type="button" class="btn btn-modal btn-danger" (click)='fermerModal()'>Fermer</button>
    </div>
    <div class="col-3">
      <button type="submit" class="btn btn-modal btn-primary" [disabled]="formGroup.invalid" (click)='onSubmit()' >Valider
        <div *ngIf="formModalIsSubmitting" class="col-3 text-center spinner-border text-success" role="status"></div>
      </button>
    </div>
  </div>
</div>
