import { AfterViewInit, Component, EventEmitter, inject, OnInit, Output, ViewChild } from '@angular/core'
import { ReferenceService } from '../../../sunleon/data-access/http/reference.service'
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { TypeReferenceDto } from '../../../sunleon/data-access/http/dto/type-reference-dto'
import { NgClass, NgForOf, NgIf } from '@angular/common'
import { NgSelectModule } from '@ng-select/ng-select'
import { BilanEnergetiqueService } from '../../../sunleon/data-access/http/bilan-energetique.service'
import { BilanEnergetiqueDto } from '../../../sunleon/data-access/http/dto/bilan-energetique-dto'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ToastrService } from 'ngx-toastr'
import { FileUploadService } from '../../../sunleon/data-access/http/file-upload.service'
import { GeomapDomainService } from '../../domain/geomap.domain'
import { Subject, takeUntil } from 'rxjs'
import { User } from '../../../shared/models/user'
import { AuthService } from '../../../core/services/auth.service'

@Component({
  selector: 'app-form-mode-valorisation',
  templateUrl: './form-mode-valorisation.component.html',
  styleUrls: ['./form-mode-valorisation.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, NgForOf, ReactiveFormsModule, NgClass, NgSelectModule],
})
export class FormModeValorisationComponent implements OnInit, AfterViewInit {
  @Output()
  formReady: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  user: User

  modeValorisationForm: FormGroup
  private readonly geomapDomainService = inject(GeomapDomainService)
  private unsubscribe$ = new Subject<void>()

  @ViewChild('content')
  modalContent: any

  modeValorisations: TypeReferenceDto[] = []
  prmCodes: BilanEnergetiqueDto[] = []
  formModalIsSubmitting: boolean = false
  formIsSubmitting: boolean = false
  selectedFile: File | undefined
  processIdEnregistrementSimulation: string | undefined
  status: string | undefined

  constructor(
    public authService: AuthService,
    private bilanEnergetiqueService: BilanEnergetiqueService,
    private referenceService: ReferenceService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private fileUploadService: FileUploadService,
    private formBuilder: FormBuilder
  ) {
    this.modeValorisationForm = this.formBuilder.group({
      modeValorisation: [null, [Validators.required]],
      puissanceSouscriteKVA: [null, [Validators.pattern('^\\d+$')]],
      travauxDistanceVoirieVrd: [null],
      distanceRaccordementPublic: [null],
      dispositifNonInjection: [false],
      dispoTGBT: [false],
      automate: [false],
      prm: [null],
      tarifHch: [null],
      tarifHph: [null],
      tarifHpt: [null],
      tarifHpb: [null],
      tarifHcb: [null],
    })
  }

  ngAfterViewInit(): void {
    this.geomapDomainService
      .getDistance$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((distance) => {
        if (this.modeValorisationForm.get('modeValorisation').value === 'AC') {
          this.modeValorisationForm.get('travauxDistanceVoirieVrd').patchValue(distance === 0 ? null : distance)
        } else {
          this.modeValorisationForm.get('distanceRaccordementPublic').patchValue(distance === 0 ? null : distance)
        }
      })
    this.modeValorisationForm
      .get('modeValorisation')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.geomapDomainService.resetCurrentZone())
  }

  ngOnInit(): void {
    this.user = this.authService.connectedUser
    this.referenceService.getModeValorisation().subscribe((data) => {
      this.modeValorisations = data
    })

    this.bilanEnergetiqueService.getPrm().subscribe((data) => {
      this.prmCodes = data
    })

    this.initModeValorisation(
      'modeValorisation',
      'prm',
      'puissanceSouscriteKVA',
      'tarifHch',
      'tarifHph',
      'tarifHpt',
      'tarifHpb',
      'tarifHcb',
      'distanceRaccordementPublic',
      'travauxDistanceVoirieVrd',
      'dispositifNonInjection',
      'dispoTGBT',
      'automate'
    )
    this.formReady.emit(this.modeValorisationForm)
  }

  onRadioClick(code: string | null, controleName: string) {
    this.modeValorisationForm.get(controleName).setValue(code)
    this.geomapDomainService.selectModeValorisationOutils()
  }

  openModal() {
    this.modalService
      .open(this.modalContent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false })
      .result.then(
        () => {
          // Traitement à effectuer lorsque le modal est fermé (si nécessaire)
          this.formModalIsSubmitting = false
        },
        () => {
          // Traitement à effectuer si le modal est fermé avec un rejet (si nécessaire)
          this.formModalIsSubmitting = false
        }
      )
  }

  onFileSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement
    if (inputElement?.files) {
      this.selectedFile = inputElement.files[0]
    }
  }

  onSubmit(): void {
    if (this.selectedFile) {
      this.formModalIsSubmitting = true
      this.fileUploadService.uploadFile(this.selectedFile).subscribe({
        next: (data: any) => {
          this.processIdEnregistrementSimulation = data.message
          this.monitorProcessStatus(this.processIdEnregistrementSimulation)
        },
        error: () => {
          this.formModalIsSubmitting = false
          this.modalService.dismissAll()
        },
      })
    } else {
      this.toastr.error('Aucun fichier sélectionné')
      this.formModalIsSubmitting = false
      this.modalService.dismissAll()
    }
  }

  monitorProcessStatus(processId: string) {
    this.fileUploadService.getProcessStatus(processId).subscribe((result) => {
      this.status = result.status
      if (this.status === 'COMPLETED') {
        this.formModalIsSubmitting = false
        this.toastr.success('Le fichier CSV a été importé avec le code PRM : ' + result.message)
        this.modeValorisationForm.get('prm').setValue(result.message)
        this.modalService.dismissAll()
        this.processIdEnregistrementSimulation = undefined
      } else {
        if (this.status === 'FAILED') {
          this.formModalIsSubmitting = false
          this.toastr.error(result.message)
          this.modalService.dismissAll()
          this.processIdEnregistrementSimulation = undefined
        } else {
          this.toastr.info(result.message)
        }
      }
    })
  }

  initModeValorisation(
    modeValorisation: string,
    prmAC: string,
    puissanceSouscriteKVAac: string,
    tarifHchAc: string,
    tarifHphAc: string,
    tarifHptAc: string,
    tarifHpbAc: string,
    tarifHcbAc: string,
    distanceRaccordementPublic: string,
    distanceVoirieVRD: string,
    dispositifNonInjectionAC: string,
    dispoTGBTAC: string,
    automateAC: string
  ) {
    this.modeValorisationForm.get(modeValorisation).valueChanges.subscribe((mode) => {
      const prm = this.modeValorisationForm.get(prmAC)
      const puissanceSouscriteKVA = this.modeValorisationForm.get(puissanceSouscriteKVAac)
      const tarifHch = this.modeValorisationForm.get(tarifHchAc)
      const tarifHph = this.modeValorisationForm.get(tarifHphAc)
      const tarifHpt = this.modeValorisationForm.get(tarifHptAc)
      const tarifHpb = this.modeValorisationForm.get(tarifHpbAc)
      const tarifHcb = this.modeValorisationForm.get(tarifHcbAc)
      const raccordementEnedis = this.modeValorisationForm.get(distanceRaccordementPublic)
      const distanceVoirie = this.modeValorisationForm.get(distanceVoirieVRD)
      const dispositifNonInjection = this.modeValorisationForm.get(dispositifNonInjectionAC)
      const dispoTGBT = this.modeValorisationForm.get(dispoTGBTAC)
      const automate = this.modeValorisationForm.get(automateAC)

      if (mode === 'AC') {
        prm.setValidators([Validators.required])
        puissanceSouscriteKVA.setValidators([Validators.required])
        tarifHch.setValidators([Validators.required])
        tarifHph.setValidators([Validators.required])
        tarifHpt.setValidators([Validators.required])
        tarifHpb.setValidators([Validators.required])
        tarifHcb.setValidators([Validators.required])
        raccordementEnedis.setValidators([Validators.nullValidator])
        raccordementEnedis.reset()
      } else {
        prm.setValidators([Validators.nullValidator])
        puissanceSouscriteKVA.setValidators([Validators.nullValidator])
        tarifHch.setValidators([Validators.nullValidator])
        tarifHph.setValidators([Validators.nullValidator])
        tarifHpt.setValidators([Validators.nullValidator])
        tarifHpb.setValidators([Validators.nullValidator])
        tarifHcb.setValidators([Validators.nullValidator])
        prm.reset()
        puissanceSouscriteKVA.reset()
        distanceVoirie.reset()
        tarifHch.reset()
        tarifHph.reset()
        tarifHpt.reset()
        tarifHpb.reset()
        tarifHcb.reset()
        raccordementEnedis.setValidators([Validators.required])
        dispositifNonInjection.setValue(false)
        dispoTGBT.setValue(false)
        automate.setValue(false)
      }
    })
  }
}
